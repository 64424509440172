import novelaTheme from '@narative/gatsby-theme-novela/src/gatsby-plugin-theme-ui';

const theme = {
  ...novelaTheme,
  initialColorMode: 'dark',
  // fonts: {
  //   sansSerif: 'Times New Roman',
  // },
  colors: {
    ...novelaTheme.colors,
    //   primary: '#000',
    //   secondary: '#73737D',
    accent: '#6166DC',
    grey: '#333',
    //   background: '#fff',
    modes: {
      ...novelaTheme.colors.modes,
      dark: {
        ...novelaTheme.colors.modes.dark,
        grey: '#fff',
        accent: '#6166DC',
      },
    },
  },
};

// console.debug({ theme });

export default theme;
